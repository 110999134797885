import React from 'react';
import { useSelector } from 'react-redux';

import { MainNav, LoggerFn } from '@videoblocks/shared-components/';

import {
  selectUser,
  selectPrimarySubscription,
  selectAuthPermissions,
  selectAuthFeatureFlags,
  selectIsLoggedIn,
} from '../../../auth/AuthSelectors';
import Logger from '../../../common/Logger';
import useMedia from '../../../common/hooks/useMedia';
import {
  selectFormattedNavMenuCollections,
  selectNavMenu,
  selectNavMenuCategory,
} from '../../../ui/UiSelectors';
import { OtherCategoryTypes } from '../../../ui/UiTypes';
import { videoOptions, audioOptions } from '../../Shared/constants';

// import './MainNavContainer.less';

const MainNavContainer = () => {
  const user = useSelector(selectUser);
  const subscription = useSelector(selectPrimarySubscription);
  const permissions = useSelector(selectAuthPermissions);
  const topLevelCategories = useSelector(selectNavMenu).top_level_categories;
  const businessCategories = useSelector(
    selectNavMenuCategory(OtherCategoryTypes.BUSINESS)
  ).categories;
  const resourcesCategories = useSelector(
    selectNavMenuCategory(OtherCategoryTypes.RESOURCES)
  ).categories;
  const creativeToolCategories = useSelector(
    selectNavMenuCategory(OtherCategoryTypes.CREATIVE_TOOLS)
  ).categories;
  const collections = useSelector(selectFormattedNavMenuCollections);
  const siteConstantData = { audioOptions, videoOptions };
  const logger: LoggerFn = (id) => {
    Logger.accessTelemetry().increment(id);
  };
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <MainNav
      resourcesCategories={resourcesCategories}
      user={user}
      subscription={subscription}
      permissions={permissions}
      businessCategories={businessCategories}
      top_level_categories={topLevelCategories}
      creativeToolCategories={creativeToolCategories}
      logger={logger}
      collections={collections}
      constants={siteConstantData}
      imagesUrl={__ASSETS_COMMON_IMAGES_URL__}
      useMedia={useMedia}
      isLoggedIn={isLoggedIn}
    />
  );
};

export default MainNavContainer;
